import React, { useState } from "react"
import * as styles from "./index.module.scss"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { BiPhoneCall } from "react-icons/bi"
import { SiMinutemailer } from "react-icons/si"
import contact from "../../config/contact"
import { FormGroup, Label, Input, Alert } from "reactstrap"
import axios from "axios"
import { toast } from "react-toastify"

// const iFrameUrl =
//   "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2703.570056629425!2d7.866875116262386!3d47.34226741419269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4790314cba465a2f%3A0xbc21b8bd64bf99b6!2sBahnhofpl.%201b%2C%204612%20Wangen%20bei%20Olten%2C%20Switzerland!5e0!3m2!1sen!2slk!4v1636297239853!5m2!1sen!2slk"

const ContactUsPage = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [progress, setProgress] = useState(false)
  const [sent, setSent] = useState(false)
  const sendEmail = async () => {
    setProgress(true)
    // send email
    await axios
      .post("/api/send-mail", {
        userEmail: email,
        subject: "New message from Contact Us page",
        fields: [
          { key: "Name", value: name },
          { key: "Email", value: email },
          { key: "Message", value: message },
        ],
      })
      .then(() => {
        setSent(true)
      })
      .catch(err => {
        console.log(err)
        toast.error("Error Occured. Please Try Again")
      })
    setProgress(false)
  }
  return (
    <>
      <Seo title="Contact" />
      <Breadcrumbs title={"Contact"} />

      {/*<section>*/}
      {/*  <iframe*/}
      {/*    src={iFrameUrl}*/}
      {/*    frameBorder="0"*/}
      {/*    title={"map"}*/}
      {/*    allowFullScreen=""*/}
      {/*    aria-hidden="false"*/}
      {/*    tabIndex="0"*/}
      {/*    className={styles.map}*/}
      {/*  />*/}
      {/*</section>*/}

      <div className="container text-center mt-5">
        <div className="row justify-content-center">
          {/*<div className="col-sm-6 col-lg-4">*/}
          {/*  <div className={styles.round_icon}>*/}
          {/*    <HiOutlineLocationMarker />*/}
          {/*  </div>*/}
          {/*  <h4 className={"text-theme"}>Our Address</h4>*/}
          {/*  <p>*/}
          {/*    Bahnhofplatz 1B,*/}
          {/*    <br />*/}
          {/*    Wangen bei Olten, 4612,*/}
          {/*    <br />*/}
          {/*    Schweiz.*/}
          {/*  </p>*/}
          {/*</div>*/}

          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <SiMinutemailer />
            </div>
            <h4 className={"text-theme"}>Questions? write us</h4>
            <p>
              <a
                href={`mailto:${contact.email}`}
                className={styles.contact_link}
              >
                {contact.email}
              </a>
            </p>
          </div>

          <div className="col-sm-6 col-lg-4">
            <div className={styles.round_icon}>
              <BiPhoneCall />
            </div>
            <h4 className={"text-theme"}>Call us</h4>
            <p>
              <a
                href={`tel:${contact.phone.link}`}
                className={styles.contact_link}
              >
                {contact.phone.display}
              </a>
            </p>
          </div>

          <div className="col-12"></div>

          <div className="col-sm-10 col-md-8 col-lg-6 mt-5 text-center">
            <h3>Say Hello!</h3>
            {sent && (
              <Alert color="success">
                We got your message. One of our agents will get back to you
                soon.
              </Alert>
            )}
            <div className="mb-3">
              <FormGroup floating>
                <Input
                  id="name"
                  name="name"
                  placeholder="Name"
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                <Label for="name">Name</Label>
              </FormGroup>
            </div>
            <div className="mb-3">
              <FormGroup floating>
                <Input
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <Label for="email">Email</Label>
              </FormGroup>
            </div>
            <div className="mb-3">
              <FormGroup floating>
                <Input
                  id="message"
                  name="message"
                  placeholder="Message"
                  type="textarea"
                  style={{ minHeight: "100px" }}
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
                <Label for="exampleEmail">Message</Label>
              </FormGroup>
            </div>
            <button
              className="btn btn-primary"
              onClick={sendEmail}
              disabled={!name || !email || !message || progress || sent}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUsPage
